import axios from 'axios'
import config from "@/config"

class Espace {


    static soumetterDossier(email, name, emailInterlocuteur) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/parent/soumetterDossier`,
                {
                    email: email,
                    name: name,
                    emailInterlocuteur: emailInterlocuteur
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    static soumetterFichier(email, file, emailInterlocuteur) {

        var formData = new FormData();
        formData.append("email", email);
        formData.append("emailInterlocuteur", emailInterlocuteur);

        for (let i = 0; i < file.length; i++) {
            formData.append(i, file[i]);
        }

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/parent/soumetterFichier`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    static readParent(email) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/parent/readParent`,
                {
                    email: email
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static updateParent(email) {

        return new Promise((resolve, reject) => {
            axios.put(`${config.launch}/parent/updateParent/${email}`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    static deleteParent(id) {

        return new Promise((resolve, reject) => {
            axios.delete(`${config.launch}/parent/deleteParent/${id}`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }



    // ____________________________ End Parent ___________________________________________
    // -------------------------- Start First -----------------------------------
    static soumetterFirstDossier(email, name, id) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/one/soumetterFirstDossier`,
                {
                    email: email,
                    name: name,
                    id: id
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static soumetterFirstFichier(email, file, id) {

        var formData = new FormData();

        formData.append("email", email);
        formData.append("id", id);

        for (let i = 0; i < file.length; i++) {
            formData.append(i, file[i]);
        }

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/one/soumetterFirstFichier`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    static readFirst(id) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/one/readFirst`,
                {
                    id: id
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // -------------------------- End First ------------------------------------------
    // -------------------------- start Second ------------------------------------------

    static soumetterSecondDossier(email, name, id) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/tow/soumetterSecondDossier`,
                {
                    email: email,
                    name: name,
                    id: id
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    static soumetterSecondFichier(email, file, id) {

        var formData = new FormData();
        formData.append("email", email);
        formData.append("id", id);

        for (let i = 0; i < file.length; i++) {
            formData.append(i, file[i]);
        }

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/tow/soumetterSecondFichier`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    static readSecond(id) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/tow/readSecond`,
                {
                    id: id
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // -------------------------- End Second ------------------------------------------

    // -------------------------- End Third ------------------------------------------
    static soumetterThirdDossier(email, name, id) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/tree/soumetterThirdDossier`,
                {
                    email: email,
                    name: name,
                    id: id
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    static soumetterThirdFichier(email, file, id) {

        var formData = new FormData();
        formData.append("email", email);
        formData.append("id", id);

        for (let i = 0; i < file.length; i++) {
            formData.append(i, file[i]);
        }

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/tree/soumetterThirdFichier`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    static readThird(id) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/tree/readThird`,
                {
                    id: id
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }
    // -------------------------- End Third ------------------------------------------

    static readFourth(id) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/four/readFourth`,
                {
                    id: id
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static soumetterFourthFichier(email, file, id) {

        var formData = new FormData();
        formData.append("email", email);
        formData.append("id", id);

        for (let i = 0; i < file.length; i++) {
            formData.append(i, file[i]);
        }

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/four/soumetterFourthFichier`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static soumetterFourthDossier(email, name, id) {

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/four/soumetterFourthDossier`,
                {
                    email: email,
                    name: name,
                    id: id
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static supprimer(id, originalname, value) {

        if (value == "parent") {
            return new Promise((resolve, reject) => {
                axios.delete(`${config.launch}/parent/supprimer/${id}/${originalname}/${value}`,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        resolve((response.data));
                    })
                    .catch(error => {
                        reject(error.response.data);
                    });
            });
        }

        if (value == "first") {
            return new Promise((resolve, reject) => {
                axios.delete(`${config.launch}/one/supprimer/${id}/${originalname}/${value}`,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        resolve((response.data));
                    })
                    .catch(error => {
                        reject(error.response.data);
                    });
            });
        }

        if (value == "second") {
            return new Promise((resolve, reject) => {
                axios.delete(`${config.launch}/tow/supprimer/${id}/${originalname}/${value}`,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        resolve((response.data));
                    })
                    .catch(error => {
                        reject(error.response.data);
                    });
            });
        }

        if (value == "third") {
            return new Promise((resolve, reject) => {
                axios.delete(`${config.launch}/tree/supprimer/${id}/${originalname}/${value}`,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        resolve((response.data));
                    })
                    .catch(error => {
                        reject(error.response.data);
                    });
            });
        }

        if (value == "fourth") {

            return new Promise((resolve, reject) => {
                axios.delete(`${config.launch}/four/supprimer/${id}/${originalname}/${value}`,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        resolve((response.data));
                    })
                    .catch(error => {
                        reject(error.response.data);
                    });
            });
        }
    }


    static download(filename, value) {

        if (value == "parent") {
            return new Promise((resolve, reject) => {
                axios.get(`${config.launch}/parent/download/${filename}/${value}`, {
                    headers: this.headers,
                    responseType: 'blob',
                })
                    .then(response => {
                        resolve((response));
                        // resolve(window.open(URL.createObjectURL(response.data)));
                    })
                    .catch(error => {
                        reject(error.response.data);
                    });
            });
        }

        if (value == "first") {
            return new Promise((resolve, reject) => {
                axios.get(`${config.launch}/one/download/${filename}/${value}`, {
                    headers: this.headers,
                    responseType: 'blob',
                })
                    .then(response => {
                        resolve((response));

                    })
                    .catch(error => {
                        reject(error.response.data);
                    });
            });
        }

        if (value == "second") {
            return new Promise((resolve, reject) => {
                axios.get(`${config.launch}/tow/download/${filename}/${value}`, {
                    headers: this.headers,
                    responseType: 'blob',
                })
                    .then(response => {
                        resolve((response));

                    })
                    .catch(error => {
                        reject(error.response.data);
                    });
            });
        }

        if (value == "third") {
            return new Promise((resolve, reject) => {
                axios.get(`${config.launch}/tree/download/${filename}/${value}`, {
                    headers: this.headers,
                    responseType: 'blob',
                })
                    .then(response => {
                        resolve((response));

                    })
                    .catch(error => {
                        reject(error.response.data);
                    });
            });
        }

        if (value == "fourth") {
            return new Promise((resolve, reject) => {
                axios.get(`${config.launch}/four/download/${filename}/${value}`, {
                    headers: this.headers,
                    responseType: 'blob',
                })
                    .then(response => {
                        resolve((response));

                    })
                    .catch(error => {
                        reject(error.response.data);
                    });
            });
        }

    }

    static read(token){

        return new Promise((resolve, reject) => {
            axios.defaults.headers.common["Authorization"] = token;
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token
                },
            };

            axios.get(`${config.launch}/espaces/read`, axiosConfig)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static readAccess(email){

        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/espaces/readAccess`,
                {
                    email: email,

                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    
    
    static validerAccess(interlocuteurs, idAccess){

        return new Promise((resolve, reject) => {
            axios.put(`${config.launch}/parent/updateAccess`,
                {
                    interlocuteurs: interlocuteurs,
                    idAccess: idAccess
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }




}

export default Espace;