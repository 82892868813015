<template>
    <div class="table_component" tabindex="0">

        <div class="buttons">
            <button v-if="!flagFourth" @click="retourSecond">Retour</button>
            <button v-if="!flagFourth" @click="handelDossier">Créer un dossier</button>
            <button v-if="!flagFourth" @click="handelFichier">Uploder fichier(s)</button>
        </div>

        <table v-if="!flagFourth">
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Nom</th>
                    <th>propriété</th>
                    <th>Date</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody v-for="(item, index) in listeTableSecond" :key="index">
                <tr>
                    <td>{{ item.originalname.length > 30 ? item.originalname.substr(0, 20) : item.originalname }}</td>
                    <td>{{ `${item.type} ${checkTypeFile(item.originalname)}` }}</td>
                    <td>{{ new Date(item.date).toLocaleDateString() }}</td>
                    <td>{{ item.prenom }} {{ item.nom }}</td>
                    <td>
                        <button class="supprimer" v-if="item.email == email || domain == 'gthconsult.ma'"
                            @click="supprimer(item._id, item.originalname, 'third')"><i
                                class="fa fa-trash"></i>supprimer</button>
                        <button class="apercu" v-if="item.type == 'Dossier'"
                            @click="voir(item._id, item.originalname)"><i class="fa fa-arrow-right"></i>Aperçu</button>
                        <button class="telecharger" v-if="item.type == 'Fichier'"
                            @click="download(item.filename, 'third', item.originalname)"><i
                                class="fa fa-download"></i>Télècharger</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />
        <Create v-if="flagCreate" :type="type" @closeConfirmation="closeConfirmation"
            @validerConfirmationDossier="validerConfirmationDossier"
            @validerConfirmationFichier="validerConfirmationFichier" />

        <TableFourth v-if="flagFourth" :idThird="idThird" :titreThird="titreThird" :email="email"
            @retourThird="retourThird" />

    </div>
</template>

<script>
import Create from "@/components/models/Transfer/Create.vue"
import TableFourth from "@/components/models/Transfer/TableFourth.vue"
import Espace from "@/connection/Espace"
import Loading from 'vue-loading-overlay';


export default {
    name: 'table_component',
    data() {
        return {
            listeTableSecond: [],
            flagCreate: false,
            type: null,
            flagFourth: false,
            idThird: null,
            titreThird: null,
            domain: null,
        }
    },
    props: {
        idSecond: String,
        titreSecond: String,
        email: String
    },

    components: {
        Loading,
        Create,
        TableFourth
    },

    methods: {


        checkTypeFile(value) {

            const extention = value.split(".")

            if (extention[1] == "pdf") {
                return "Pdf";
            }

            else if (extention[1] == "txt") {
                return "Txt";
            }

            else if (extention[1] == "docx") {
                return "Word";
            }

            else if (extention[1] == "doc") {
                return "Word";
            }

            else if (extention[1] == "rar") {
                return "Rar"
            }

            else if (extention[1] == "zip") {
                return "Zip"
            }

            else if (extention[1] == "pptx") {
                return "pptx";
            }

            else if (extention[1] == "ppt") {
                return "Power point";
            }

            else if (extention[1] == "png") {
                return "Image png";
            }

            else if (extention[1] == "mp4") {
                return 'video mp4';
            }

            else if (extention[1] == "jpeg") {
                return "Image Jpeg";
            }

            else if (extention[1] == "jpg") {
                return "Image Jpeg";
            }

            else {
                return "None";
            }

        },

        supprimer(id, originalname, value) {

            Espace.supprimer(id, originalname, value)
                .then((result) => {
                    if (result == true) {
                        const index = this.listeTableSecond.findIndex((el) => el._id == id)
                        this.listeTableSecond.splice(index, 1);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },

        downloadFile(response, title, typeContent) {

            var newBlob = new Blob([response.data], {
                type: typeContent
            });

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
            link.download = title;
            link.click();
            setTimeout(function () {
                window.URL.revokeObjectURL(data);
            }, 100);

        },

        download(filename, value, originalname) {

            const extention = originalname.split('.').pop();

            var typeContent = null;

            if (extention == "pdf") {
                typeContent = "application/pdf";
            }

            if (extention == "txt") {
                typeContent = "text/plain";
            }

            if (extention == "docx") {
                typeContent = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            }

            if (extention == "doc") {
                typeContent = "application/msword";
            }

            if (extention == "rar") {
                typeContent = "application/vnd.rar";
            }

            if (extention == "zip") {
                typeContent = "application/vnd.rar";
            }

            if (extention == "pptx") {
                typeContent = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
            }

            if (extention == "ppt") {
                typeContent = "application/vnd.ms-powerpoint";
            }

            if (extention == "png") {
                typeContent = "image/png";
            }

            if (extention == "mp4") {
                typeContent = "video/mp4";
            }

            if (extention == "jpeg") {
                typeContent = "image/jpeg";
            }

            if (extention == "jpg") {
                typeContent = "image/jpeg";
            }

            Espace.download(filename, value)
                .then((result) => {
                    this.downloadFile(result, originalname, typeContent);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        voir(id, name) {
            this.idThird = id;
            this.titreThird = name;
            this.flagFourth = true;
        },

        retourThird() {
            this.flagFourth = false;
        },

        retourSecond() {
            return this.$emit("retourSecond")
        },

        suprimer(id) {
            console.log(id);
        },

        modifier(id) {
            console.log(id);
        },

        handelDossier() {
            this.type = "dossier";
            this.flagCreate = true;
        },

        handelFichier() {
            this.type = "fichier";
            this.flagCreate = true;
        },

        closeConfirmation() {
            this.type = null;
            this.flagCreate = false;
        },

        validerConfirmationDossier(name) {

            this.isLoading = true;
            this.type = null;
            this.flagCreate = false;

            Espace.soumetterThirdDossier(this.email, name, this.idSecond)
                .then((result) => {
                    this.listeTableSecond.unshift(result.data);
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error.message)
                })

        },


        // valider fichier
        validerConfirmationFichier(document) {

            this.isLoading = true;
            this.type = null;
            this.flagCreate = false;

            Espace.soumetterThirdFichier(this.email, document, this.idSecond)
                .then((result) => {
                    for (let i = 0; i < result.data.length; i++) {
                        this.listeTableSecond.unshift(result.data[i]);
                    }
                    result.data = [];
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error.message);
                })

        },


    },

    created() {

        const val = this.email.split("@");
        this.domain = val[1];

        Espace.readThird(this.idSecond)
            .then((result) => {
                console.log(result.data)
                this.listeTableSecond = result.data.listeTree;
            })
            .catch((error) => {
                console.log(error);
            });
    }
};
</script>

<style scoped>
.table_component {
    overflow: auto;
    width: 100%;
}

.table_component .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.table_component .buttons button {
    border-radius: 25px;
    font-size: large;
    margin-left: 5px;
    margin-right: 5px;
    color: #0057e3;
    outline: 1px solid #0057e3;
    padding: 10px;
    background-color: white;
}

.table_component .buttons button:hover {
    background-color: rgba(224, 224, 224, 0.808);
}

.table_component table {
    border: 1px inset #dededf;
    height: 100%;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 1px;
    text-align: left;
}

.table_component caption {
    caption-side: top;
    text-align: left;
}

.table_component th {
    border: 1px inset #dededf;
    background-color: #777c7e;
    color: #ffffff;
    padding: 5px;
}

.table_component td {
    border: 1px inset #dededf;
    background-color: #f7f7f7;
    color: #000000;
    padding: 5px;
}

.apercu,
.telecharger {
    color: white;
    background-color: #348334;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
}

.apercu:hover,
.telecharger:hover {
    background-color: green
}

.supprimer {
    color: white;
    background-color: #d31111b0;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
}

.supprimer:hover {
    background-color: red;
}

.fa {
    margin-right: 5px;
}

table>tbody>tr>td:nth-child(5) {
    display: flex;
    flex-direction: row;
}

table>tbody>tr>td:nth-child(5) button {
    margin-left: 10px;
    margin-right: 10px;
}
</style>